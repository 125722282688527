import { ErrorBoundary } from '@red-digital/bricks';
import { CohesionProvider } from '@redventures/cohesion-utils-react';
import type { AppProps } from 'next/app';
import { useEffect } from 'react';
import { recursivelyUpdateLinks } from '~/helper/trackURLParams';
import NewRelic from '~/lib/newrelic/NewRelic';
import { getGCLID } from '~/lib/segment/gclid';
import { getAnonymousID } from '~/helper/parseURLParams';
import useParamsTracking from '~/lib/hooks/useParamsTracking';
import ReducedMotionContext from '~/lib/ReducedMotionProvider';
import useReduceMotion from '~/helper/reduceMotion';
import '~/styles/globals.scss';
import { componentLookup } from '~/helper/componentLookup';

if (typeof window !== 'undefined') {
  const gclid = getGCLID();

  document.addEventListener('DOMContentLoaded', () => {
    if (typeof window === 'undefined') return;

    const body = document.querySelector<HTMLBodyElement>('body');

    if (!body) return;

    recursivelyUpdateLinks(body.childNodes, gclid);

    // MutationObserver calls a given callback function whenever
    // a desired DOM Node's children are updated. In this case,
    // we watch the `body` element, and run recursivelyUpdateLinks
    // when that happens. Hover over recursivelyUpdateLinks or
    // run `npm run start:docs` for more details
    const observer = new MutationObserver((mutationList) => {
      for (const mutation of mutationList) {
        if (mutation.type === 'childList') {
          recursivelyUpdateLinks(mutation.addedNodes, gclid);
        }
      }
    });
    observer.observe(body, {
      attributes: true,
      childList: true,
      subtree: true,
    });
  });
}

const pages = {
  '/': { page: '/' },
  '/health': { page: '/health' },
};
export async function getStaticPaths() {
  const paths = Object.keys(pages).map((page) => ({
    params: { page },
  }));

  return { paths, fallback: false };
}

/* eslint-disable consistent-return */
function App({ Component, pageProps }: AppProps) {
  useParamsTracking();

  const isReducedMotion = useReduceMotion();

  useEffect(() => {
    if (typeof window === 'undefined') return;

    // Beacon pageview event for W+ tracking
    if (typeof window._bcq !== 'undefined') {
      const data = {
        nm: {
          nm: 'rvLandingPage',
        },
        co: {
          ty: 'RV MLP',
          mx: getAnonymousID(),
        },
        mb: {
          st: '',
          ms: '',
        },
      };
      window._bcq.push([
        '_tagAction',
        'wplus',
        'pageView',
        'rvLandingPage',
        data,
      ]);
    }
  }, []);

  return (
    <ErrorBoundary
      production={process.env.ENVIRONMENT === 'production'}
      title='Client Error'
      message='An unexpected error occurred that prevented this page from loading.'
    >
      <ReducedMotionContext.Provider value={{ isReducedMotion }}>
        <NewRelic />
        <CohesionProvider components={componentLookup}>
          <Component {...pageProps} />
        </CohesionProvider>
      </ReducedMotionContext.Provider>
    </ErrorBoundary>
  );
}

export default App;
